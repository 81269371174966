import React, { useState, useEffect } from 'react';
import Chart from 'react-google-charts'; // Assuming you're using Google Charts
import api from './api';

function getRandomColor() {
    // Generate random values for RGB components
    var r = Math.floor(Math.random() * 256); // Random red value between 0 and 255
    var g = Math.floor(Math.random() * 256); // Random green value between 0 and 255
    var b = Math.floor(Math.random() * 256); // Random blue value between 0 and 255
  
    // Convert RGB to hexadecimal format
    var color = "#" + ("000000" + ((r << 16) | (g << 8) | b).toString(16)).slice(-6);
  
    return color;
}

const MultiHeroTotalBurnedCardsChart = ({ usernames }) => {
    const [data, setData] = useState([]);
    const [colors, setColor] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDataForUser = async (username) => {
            try {
                const response = await api.get(`https://api.shark43.com/total_cards_over_time/${username}`);
                const cls = {}
                usernames.map((username => {
                    cls[username] = getRandomColor();
                }))
                console.log(cls)
                setColor(cls)
                return response.data;
            } catch (error) {
                setError(error);
                setLoading(false);
                return null;
            }
        };

        const fetchDataForAllUsers = async () => {
            if(usernames && Array.isArray(usernames) && usernames.length > 0) {
                try {
                    const allUserData = await Promise.all(usernames.map(username => fetchDataForUser(username)));
                    // Combine data for all users
                    const combinedData = allUserData.reduce((acc, userData, index) => {
                        if (userData) {
                            userData.forEach(item => {
                                // Add username as a property to distinguish data points
                                acc.push({ ...item, username: usernames[index] });
                            });
                        }
                        return acc;
                    }, []);
                    setData(combinedData);
                    setLoading(false);
                } catch (error) {
                    setError(error);
                    setLoading(false);
                }
            }           
        };

        fetchDataForAllUsers();
    }, [usernames]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

 // Prepare data for the chart
 const chartData = [
    ['Time', ...usernames.map(username => `Score - ${username}`), { role: 'style' }],
    ...data.map(item => [new Date(item.timestamp), ...usernames.map(username => (item.username === username ? item.burnedCardsCount : null)), colors[item.username]])
];

    // Find the minimum and maximum floor values across all users
    const minFloor = Math.min(...data.map(item => item.burnedCardsCount));
    const maxFloor = Math.max(...data.map(item => item.burnedCardsCount));

    // Add padding to the vertical axis range
    const padding = 0.05; // Adjust the padding as needed
    const minY = minFloor - (maxFloor - minFloor) * padding;
    const maxY = maxFloor + (maxFloor - minFloor) * padding;

    return (
        <div>
            <h2>MULTI HERO TOTAL BURNED CARDS Chart</h2>
            <Chart
                width={'100%'}
                height={'400px'}
                chartType="LineChart"
                loader={<div>Loading Chart...</div>}
                data={chartData}
                options={{
                    title: 'Total cards burned Over Time',
                    hAxis: {
                        title: 'Time',
                        format: 'MMM d, yyyy HH:mm',
                    },
                    vAxis: {
                        title: 'Cards',
                        maxValue: maxY + (maxY * 0.008),
                        minValue: minY - (minY * 0.008),
                    },
                    legend: 'none',
                    pointSize: 4,
                    backgroundColor: 'lightblue', // Light gray background for the chart area
                    fontName: 'Arial', // Set font for chart labels
                    fontSize: 12, // Set font size for chart labels
                }}
            />
        </div>
    );
};

export default MultiHeroTotalBurnedCardsChart;
