// Home.js

import React from 'react';
import BestTeamWrapper2 from './BestTeamWrapper2';
import ShowBestDeck5 from './ShowBestDeck5';
import ShowBestDeck from './ShowBestDeck';
const BestDeck2 = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <BestTeamWrapper2/>
            {/* <ShowBestDeck5 />
            <ShowBestDeck /> */}
      </div>
    );
}

export default BestDeck2;
