// Home.js

import React from 'react';

import TopChangeBurnedDataTable from './TopChangeBurnedDataTable';
const TopBurnedCardsPage = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <TopChangeBurnedDataTable />
      </div>
    );
}

export default TopBurnedCardsPage;
