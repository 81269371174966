import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Avatar } from '@mui/material';
import { TableSortLabel } from '@mui/material';
import api from './api';

import { Link } from 'react-router-dom';

const TopChangeHerosDataTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orderBy, setOrderBy] = useState('scoreChangePercentage');
  const [order, setOrder] = useState('asc');

  useEffect(() => {
    const fetchData = async () => {
      try {
        api.get('https://api.shark43.com/top-score-change-percentages')
          .then(response => {
            setData(response.data);
            setLoading(false);
          })
          .catch(error => {
            setError(error);
            setLoading(false);
          });
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSort = columnId => {
    const isAsc = orderBy === columnId && order === 'asc';
    setOrderBy(columnId);
    setOrder(isAsc ? 'desc' : 'asc');
    // Sort data array here
    setData([...data].sort((a, b) => {
      if (isAsc) {
        return a[columnId] < b[columnId] ? -1 : 1;
      } else {
        return a[columnId] > b[columnId] ? -1 : 1;
      }
    }));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead id="TableHeadM">
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'name'}
                direction={orderBy === 'name' ? order : 'asc'}
                onClick={() => handleSort('name')}
              >
                Username
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                  active={orderBy === 'rank'}
                  direction={orderBy === 'rank' ? order : 'asc'}
                  onClick={() => handleSort('rank')}
                >
                  Rank
                </TableSortLabel>
            </TableCell>            
            <TableCell>
              <TableSortLabel
                  active={orderBy === 'star'}
                  direction={orderBy === 'star' ? order : 'asc'}
                  onClick={() => handleSort('star')}
                >
                  Star
                </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                  active={orderBy === 'earliestScore'}
                  direction={orderBy === 'earliestScore' ? order : 'asc'}
                  onClick={() => handleSort('earliestScore')}
                >
                  Earliest score
                </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                  active={orderBy === 'latestScore'}
                  direction={orderBy === 'latestScore' ? order : 'asc'}
                  onClick={() => handleSort('latestScore')}
                >
                  Latest score
                </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                  active={orderBy === 'scoreChangePercentage'}
                  direction={orderBy === 'scoreChangePercentage' ? order : 'asc'}
                  onClick={() => handleSort('scoreChangePercentage')}
                >
                  CHANGE % (30 M)
                </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={index} component={Link} to={`/hero/${item.handle}`}
              className={
                item.scoreChangePercentage < -10 ? 'red'
                  : item.scoreChangePercentage >= -1 && item.scoreChangePercentage < 0 ? 'v-red'
                  : item.scoreChangePercentage >= -10 && item.scoreChangePercentage < 0 ? 'light-red'
                  : item.scoreChangePercentage >= 0 && item.scoreChangePercentage < 1 ? 'light-green'
                  : item.scoreChangePercentage >= 1 && item.scoreChangePercentage < 10 ? 'light-green'
                  : 'green'
              }
            >
              <TableCell>
                <Avatar alt={item.name} 
                    src={`https://www.fantasy.top/_next/image?url=https%3A%2F%2Ffantasy-top-cards.s3.eu-north-1.amazonaws.com%2Fv1%2Fcommon%2F${item?.handle}_${item?.star}.png&w=640&q=75`}
                />
              </TableCell>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.rank}</TableCell>
              <TableCell>{item.star}</TableCell>
              <TableCell>{parseFloat(item.earliestScore).toFixed(2)}</TableCell>
              <TableCell>{parseFloat(item.latestScore).toFixed(2)}</TableCell>
              <TableCell>{parseFloat(item.scoreChangePercentage).toFixed(2)}</TableCell>
              {/* Add more TableCell components for additional data fields */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TopChangeHerosDataTable;
