import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid, Avatar, Link } from '@mui/material';
import XIcon from '@mui/icons-material/X';
import api from './api';
import { Link as LinkRouter } from 'react-router-dom';
import './HeroProfileCardStyles.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import StarIcon from '@mui/icons-material/Star';
const HeroProfileCard = ({ username }) => {
    // Stato per memorizzare i dati dell'utente
    const [userData, setUserData] = useState(null);
  
    // Effetto per caricare i dati dell'utente tramite API al caricamento della pagina
    useEffect(() => {
      // Esegui la chiamata API per ottenere i dati dell'utente
      const fetchUserData = async () => {
        try {
          const response = await api.get('https://api.shark43.com/hero/'+username);
          setUserData(response.data);
        } catch (error) {
          console.error('Error while fetching hero profile data', error);
        }
      };
    
      fetchUserData();
    }, []);
  
    let stars = [];

    if(userData && userData.star){
      stars = [];
      for (let i = 0; i < userData?.star; i++) {
        stars.push(<StarIcon key={'StarIcon'+i}/>)
      }
    }

    return (
      <Card className="user-card" sx={{ display: "flex", flexDirection: "column" }}>
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{display: "flex"}}>
            <LinkRouter to={-1}>
                <ArrowBackIcon/>
              </LinkRouter>
              <LinkRouter to={'/'}>
                <Typography variant="body1">Home</Typography>
              </LinkRouter>
            </div>
            <Link href={`https://twitter.com/${userData?.handle}`} target="_blank" rel="noopener noreferrer">
              <XIcon color="primary" />
            </Link>
          </Grid>
          <Grid item xs={4}>
            {/* {userData && userData.img ? (
              <Avatar src={userData.img} alt="Profile image" sx={{ width: 100, height: 100 }} />
            ) : (
              <Avatar />
            )} */}
            <img style={{width: '15rem'}}  src={`https://www.fantasy.top/_next/image?url=https%3A%2F%2Ffantasy-top-cards.s3.eu-north-1.amazonaws.com%2Fv1%2Fcommon%2F${userData?.handle}_${userData?.star}.png&w=640&q=75`}/>
          </Grid>
          <Grid item xs={8}>
            <Link href={`https://twitter.com/${userData?.handle}`} target="_blank" rel="noopener noreferrer">
              <Typography variant="h5">{userData && userData.username}</Typography>
            </Link>
            
            <Typography variant="body1">
              Rank: {userData && userData.rank}
            </Typography>
            <Typography variant="body1">
              Score: {userData && parseFloat(userData.score).toFixed(2)}
            </Typography>
            <Typography variant="body1">
              Floor: {userData && userData.floor}
            </Typography>
            <Typography variant="body1">
              { stars }
            </Typography>
            <Typography variant="body1">
              Followers: {userData && userData?.followers}
            </Typography>
            <Typography variant="body1">
              Views: {userData && userData?.views}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    )
  };
  
export default HeroProfileCard;
  