import React, { useEffect, useState } from 'react';
import StockChartGeneral from './StockChartGeneral';
import FormatToggle from './FormatToggle';


const StockChartFantasyScoreChart = ({username}) => {
    
    const [selectedFormat, setSelectedFormat] = useState('1');

    const handleFormatChange = (event, newFormat) => {
        if (newFormat !== null) {
            setSelectedFormat(newFormat);
        }
    };

    const options = [
        { value: "1", label: "1 Hour" },
        { value: "4", label: "4 Hours" },
        { value: "8", label: "8 Hours" },
        { value: "24", label: "24 Hours" }
    ];

    const uri = `https://api.shark43.com/fantasy_score_over_time/${username}`
    const valuesName = 'fantasy_score'
    const title = `Fantasy Score Chart (By ${selectedFormat} Hour)`
    const axisY = 'Fantasy Score'
    
    return (
        <div>
            <FormatToggle options={options} selectedFormat={selectedFormat}  onFormatChange={handleFormatChange}/>
            <StockChartGeneral username={username} title={title} type={selectedFormat} valuesName={valuesName} axisY={axisY} uri={uri}/>
        </div>
    );
};

export default StockChartFantasyScoreChart;
