// api.js
import axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';

// Create an Axios instance
const api = axios.create();
const axs = setupCache(api, {
    maxAge: 15 * 60 * 1000, // Cache for 15 minutes
});

export default axs;