import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Avatar, Container } from '@mui/material';
import { TableSortLabel } from '@mui/material';
import api from './api';
import SearchBar from './SearchBar';

import { Link } from 'react-router-dom';

const HerosDataTable = () => {
  const [data, setData] = useState([]);
  const [dataSorted, setDataSorted] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orderBy, setOrderBy] = useState('rank');
  const [order, setOrder] = useState('asc');
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        api.get('https://api.shark43.com/heros')
          .then(response => {
            setData(response.data);
            setLoading(false);
          })
          .catch(error => {
            setError(error);
            setLoading(false);
          });
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSort = columnId => {
    const isAsc = orderBy === columnId && order === 'asc';
    setOrderBy(columnId);
    setOrder(isAsc ? 'desc' : 'asc');
    // Sort data array here
    setData([...data].sort((a, b) => {
      if (isAsc) {
        return a[columnId] < b[columnId] ? -1 : 1;
      } else {
        return a[columnId] > b[columnId] ? -1 : 1;
      }
    }));
  };

  useEffect(() => {
    if(searchQuery === '' || !searchQuery || searchQuery === null || searchQuery === undefined) {
      setDataSorted(data)
    } else {
      const filteredHeroes = data.filter(hero => {
        const username = hero.username.toLowerCase();
        const handle = hero.handle.toLowerCase();
        const searchTextLower = searchQuery.toLowerCase();
        
        return username.includes(searchTextLower) || handle.includes(searchTextLower);
      });
      setDataSorted(filteredHeroes)
    }
  }, [searchQuery, data]) 

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleSearch = query => {
    setSearchQuery(query);
  };

  return (
    <Container sx={{pt: 2, pb: 2}}>
    <SearchBar  onSearch={handleSearch} />
    <TableContainer component={Paper}>
      <Table>
        <TableHead id="TableHeadM">
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'username'}
                direction={orderBy === 'username' ? order : 'asc'}
                onClick={() => handleSort('username')}
              >
                Username
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                  active={orderBy === 'rank'}
                  direction={orderBy === 'rank' ? order : 'asc'}
                  onClick={() => handleSort('rank')}
                >
                  Rank
                </TableSortLabel>
            </TableCell>            
            <TableCell>
              <TableSortLabel
                  active={orderBy === 'star'}
                  direction={orderBy === 'star' ? order : 'asc'}
                  onClick={() => handleSort('star')}
                >
                  Star
                </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                  active={orderBy === 'floor'}
                  direction={orderBy === 'floor' ? order : 'asc'}
                  onClick={() => handleSort('floor')}
                >
                  Floor
                </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                  active={orderBy === 'fantasy_score'}
                  direction={orderBy === 'fantasy_score' ? order : 'asc'}
                  onClick={() => handleSort('fantasy_score')}
                >
                  Score
                </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                  active={orderBy === 'scoreChangePercentage'}
                  direction={orderBy === 'scoreChangePercentage' ? order : 'asc'}
                  onClick={() => handleSort('scoreChangePercentage')}
                >
                  Score %
                </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                  active={orderBy === 'followers'}
                  direction={orderBy === 'followers' ? order : 'asc'}
                  onClick={() => handleSort('followers')}
                >
                  Followers
                </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                  active={orderBy === 'views'}
                  direction={orderBy === 'views' ? order : 'asc'}
                  onClick={() => handleSort('views')}
                >
                  Views
                </TableSortLabel>
            </TableCell>
            {/* Add more table headers as needed */}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataSorted.map((item, index) => (
            <TableRow key={'TableRow'+item.handle} component={Link} to={`/hero/${item.handle}`}
              className={
                item.scoreChangePercentage < -10 ? 'red'
                  : item.scoreChangePercentage >= -1 && item.scoreChangePercentage < 0 ? 'v-red'
                  : item.scoreChangePercentage >= -10 && item.scoreChangePercentage < 0 ? 'light-red'
                  : item.scoreChangePercentage >= 0 && item.scoreChangePercentage < 1 ? 'light-green'
                  : item.scoreChangePercentage >= 1 && item.scoreChangePercentage < 10 ? 'light-green'
                  : 'green'
              }
            >
              <TableCell>
                <Avatar alt={item.username} src={item.img} />
              </TableCell>
              <TableCell>{item.username}</TableCell>
              <TableCell>{item.rank}</TableCell>
              <TableCell>{item.star}</TableCell>
              <TableCell>{item.floor}</TableCell>
              <TableCell>{(item.fantasy_score).toFixed(1)}</TableCell>
              <TableCell>{parseFloat((item.scoreChangePercentage)).toFixed(1)} %</TableCell>
              <TableCell>{item.followers}</TableCell>
              <TableCell>{item.views}</TableCell>
              {/* Add more TableCell components for additional data fields */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Container>
  );
};

export default HerosDataTable;
