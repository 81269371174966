// Home.js

import React, { useState, useEffect } from 'react';

import HerosDataTable from './HerosDataTable';
import ScatterPlot from './ScatterPlot';
const Home = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            {/* <ScatterPlot/> */}
            <HerosDataTable />
      </div>
    );
}

export default Home;
