import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, TextField, Button, Container, Typography, Paper, Grid, Card, CardMedia, CardContent, Rating } from '@mui/material';
import api from './api';
import './HeroProfileCardStyles.css';
import ShowBestDeck5 from './ShowBestDeck5';


const BestTeamWrapper3 = () => {
    const [bestTeams, setBestTeamsx] = useState([]);
    const [loading, setLoading] = useState(true);
    const [max_star, setMaxStar] = useState(25);

    const [jsonInput, setJsonInput] = useState('');
    const [transformedData, setTransformedData] = useState([]);

    const fetchUserData = async (params = {}) => {
        setLoading(true);
        try {
            const response = await api.post('https://api.shark43.com/find_best_team_json_2', { ...params });
            console.log('best deck', response.data);
            setBestTeamsx(response.data);
        } catch (error) {
            console.error('Error while fetching hero profile data', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
      const filters = {
          stars: max_star,
          excluded_handles: [],
          heroes_for_team : transformedData,
      };
      fetchUserData(filters);
    }, [max_star, transformedData]);    
    

    const handleInputChange = (event) => {
        setJsonInput(event.target.value);
    };

    const transformData = () => {
        try {
            const jsonData = JSON.parse(jsonInput);

            const data = jsonData.data.tactics_hero_choices.map(choice => {
                return {
                    handle: choice.hero_score.hero.handle,
                    hero_id: choice.hero_score.hero_id,
                    star: choice.hero_score.stars,
                    scores: choice.hero_score.hero.tactic_scores.map(score => score.fantasy_score),
                    floor: 0
                };
            });
            console.log('NUMERO EROI', data.length)
            setTransformedData(data);
        } catch (error) {
            console.error("Invalid JSON input", error);
        }
    };

    return (
      <Container>
        <Box>
          <Container>
              <Typography variant="h4" gutterBottom>JSON Transformer</Typography>
              <TextField
                  label="Paste JSON Here"
                  multiline
                  rows={10}
                  variant="outlined"
                  fullWidth
                  value={jsonInput}
                  onChange={handleInputChange}
              />
              <Button variant="contained" color="primary" onClick={transformData} style={{ marginTop: 20 }}>
                  Transform Data
              </Button>
              {transformedData.length > 0 && (
                  <Paper elevation={3} style={{ marginTop: 20, padding: 20 }}>
                      <Typography variant="h5">Transformed Data</Typography>
                      <pre>{JSON.stringify(transformedData, null, 2)}</pre>
                  </Paper>
              )}
          </Container>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                    <CircularProgress />
                </Box>
            ) : (

                bestTeams.length === 0 ? (
                  <>
                    <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                      <Typography variant="h1" align="center" gutterBottom>
                          There are no decks that meet these parameters
                        </Typography>
                    </Box>
                  </>
                ) : (
                  <Container sx={{pt: 2, pb: 2}}>
                    {bestTeams.map((team, i) => (<ShowBestDeck5 max_star={max_star} key={i} number={i + 1} team={team}/>))}
                  </Container>
                )
              
                
            )
            }
        </Box>
      </Container>
    );
};

export default BestTeamWrapper3;
