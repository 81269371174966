import React, { useState, useEffect } from 'react';
import api from './api';
import { Chart } from 'react-google-charts';

const HeroFollowersChart = ({ username }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
        try {
            const response = await api.get(`https://api.shark43.com/hero_followers_over_time/${username}`);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
        };

        fetchData();
    }, [username]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    const chartData = [
        ['Time', 'Followers'],
        ...data.map(item => [new Date(item.timestamp), item.followers])
    ];

    // Find the minimum and maximum floor values
    const minValue = Math.min(...data.map(item => item.followers));
    const maxValue = Math.max(...data.map(item => item.followers));

    // Add padding to the vertical axis range
    const padding = 0.05; // Adjust the padding as needed
    const minY = minValue - (maxValue - minValue) * padding;
    const maxY = maxValue + (maxValue - minValue) * padding;

    console.log(padding, minY, maxY)

    return (
        <div className="chart-container">
      <h2>Followers Chart for {username}</h2>
      <Chart
        width={'100%'}
        height={'400px'}
        chartType="LineChart"
        loader={<div>Loading Chart...</div>}
        data={chartData}
        options={{
          title: 'Followers Over Time',
          hAxis: {
            title: 'Time',
            format: 'MMM d, yyyy HH:mm',
          },
          vAxis: {
            title: 'Followers',
            maxValue: maxY + (maxY * 0.008),
            minValue: minY - (minY * 0.008),
          },
          legend: 'none',
          pointSize: 4,
          // Add custom chart options here for styling (refer to your charting library's documentation)
          backgroundColor: '#f0f0f0', // Light gray background for the chart area
          fontName: 'Arial', // Set font for chart labels
          fontSize: 12, // Set font size for chart labels
          colors: ['#2196f3'], // Set chart line color (blue)
          hAxis: {
            gridlines: { color: '#eee' }, // Set gridline color for x-axis
            textStyle: { color: '#666' }, // Set text color for x-axis labels
          },
          vAxis: {
            gridlines: { color: '#eee' }, // Set gridline color for y-axis
            textStyle: { color: '#666' }, // Set text color for y-axis labels
          },
        }}
      />
    </div>
    );
};

export default HeroFollowersChart;
