import React, { useEffect, useState } from 'react';
import CanvasJSReact from '@canvasjs/react-stockcharts';
import api from './api';

const StockChartGeneral = ({username, type = 1, uri, valuesName, title, axisY}) => {
    const [candleData, setCandleData] = useState([]);

    useEffect(() => {
        fetchData();
    }, [type]);

    const fetchData = async () => {
        try {
            const response = await api.get(uri); // Replace '/api/data' with your actual API endpoint
            const data = response.data
            let candles;
            if(type == 1){
                candles = data.reduce((acc, curr) => {
                    const timestamp = new Date(curr.timestamp);
                    timestamp.setMinutes(0);
                    timestamp.setSeconds(0);
                    timestamp.setMilliseconds(0);
                
                    const hour = timestamp.getTime();
                
                    if (!acc[hour]) {
                        acc[hour] = {
                            open: curr[valuesName],
                            high: curr[valuesName],
                            low: curr[valuesName],
                            close: curr[valuesName]
                        };
                    } else {
                        acc[hour].high = Math.max(acc[hour].high, curr[valuesName]);
                        acc[hour].low = Math.min(acc[hour].low, curr[valuesName]);
                        acc[hour].close = curr[valuesName];
                    }
                
                    return acc;
                }, {});
            } else if(type ==4) {
                // Calculate statistics by 4-hour intervals
                candles = data.reduce((acc, curr) => {
                    const timestamp = new Date(curr.timestamp);
                    timestamp.setMinutes(0);
                    timestamp.setSeconds(0);
                    timestamp.setMilliseconds(0);

                    const interval = Math.floor(timestamp.getTime() / (4 * 60 * 60 * 1000)) * (4 * 60 * 60 * 1000);

                    if (!acc[interval]) {
                        acc[interval] = {
                            open: curr[valuesName],
                            high: curr[valuesName],
                            low: curr[valuesName],
                            close: curr[valuesName]
                        };
                    } else {
                        acc[interval].high = Math.max(acc[interval].high, curr[valuesName]);
                        acc[interval].low = Math.min(acc[interval].low, curr[valuesName]);
                        acc[interval].close = curr[valuesName];
                    }

                    return acc;
                }, {});
            } else if(type == 8){
                candles = data.reduce((acc, curr) => {
                    const timestamp = new Date(curr.timestamp);
                    timestamp.setMinutes(0);
                    timestamp.setSeconds(0);
                    timestamp.setMilliseconds(0);
                
                    const interval = Math.floor(timestamp.getTime() / (8 * 60 * 60 * 1000)) * (8 * 60 * 60 * 1000);
                
                    if (!acc[interval]) {
                        acc[interval] = {
                            open: curr[valuesName],
                            high: curr[valuesName],
                            low: curr[valuesName],
                            close: curr[valuesName]
                        };
                    } else {
                        acc[interval].high = Math.max(acc[interval].high, curr[valuesName]);
                        acc[interval].low = Math.min(acc[interval].low, curr[valuesName]);
                        acc[interval].close = curr[valuesName];
                    }
                
                    return acc;
                }, {});
            } else {
                candles = data.reduce((acc, curr) => {
                    const timestamp = new Date(curr.timestamp);
                    timestamp.setMinutes(0);
                    timestamp.setSeconds(0);
                    timestamp.setMilliseconds(0);
                
                    const interval = Math.floor(timestamp.getTime() / (24 * 60 * 60 * 1000)) * (24 * 60 * 60 * 1000);
                
                    if (!acc[interval]) {
                        acc[interval] = {
                            open: curr[valuesName],
                            high: curr[valuesName],
                            low: curr[valuesName],
                            close: curr[valuesName]
                        };
                    } else {
                        acc[interval].high = Math.max(acc[interval].high, curr[valuesName]);
                        acc[interval].low = Math.min(acc[interval].low, curr[valuesName]);
                        acc[interval].close = curr[valuesName];
                    }
                
                    return acc;
                }, {});
            }
            
                        // Format data for CanvasJS
            const candleData = Object.keys(candles).map(hour => ({
                x: new Date(parseInt(hour)),
                y: [
                    candles[hour].open,
                    candles[hour].high,
                    candles[hour].low,
                    candles[hour].close
                ]
            }));
            setCandleData(candleData)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    

    const options = {
        title: {
            text: title
        },
        axisX: {
            title: "Hour",
            valueFormatString: "DD/MM HH:00:00"
        },
        axisY: {
            title: axisY
        },
        toolTip: {
            shared: true,
            contentFormatter: function (e) {
                let content = "<b>" + CanvasJSReact.CanvasJS.formatDate(e.entries[0].dataPoint.x, "YYYY-MM-DD HH:mm:ss") + "</b><br/>";
                e.entries.forEach(function (entry) {
                    content += "Open: " + entry.dataPoint.y[0] + "<br/>";
                    content += "High: " + entry.dataPoint.y[1] + "<br/>";
                    content += "Low: " + entry.dataPoint.y[2] + "<br/>";
                    content += "Close: " + entry.dataPoint.y[3] + "<br/>";
                });
                return content;
            }
        },
        data: [{
            type: "candlestick",
            risingColor: "green",
            fallingColor: "red",
            dataPoints: candleData
        }]
    };

    
    return (
        <div>
            <CanvasJSReact.CanvasJSChart options={options} />
        </div>
    );
};

export default StockChartGeneral;
