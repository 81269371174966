// Home.js
import React, { useState } from 'react';
import SearchAutocomplete from './SearchAutocomplete';
import MultiHeroScoreChart from './MultiHeroScoreChart';
import MultiUserFloorChart from './MultiUserFloorChart';
import MultiHeroTotalCardsChart from './MultiHeroTotalCardsChart';
import MultiHeroTotalBurnedCardsChart from './MultiHeroTotalBurnedCardsChart';
import { Typography } from '@mui/material';

const Charts = () => {
    const [selectedUsers, setSelectedUsers] = useState([]);

    const handleSelectUsers = (users) => {
        setSelectedUsers(users);
    };
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', paddingTop: '20px' }}>
            <SearchAutocomplete onSelectUsers={handleSelectUsers}/>
            {
                selectedUsers.length == 0 ? (
                    <Typography variant="h3">Select heros to start see charts</Typography>
                ) : (
                    <>
                        <MultiHeroScoreChart usernames={selectedUsers}/>
                        <MultiUserFloorChart usernames={selectedUsers}/>
                        <MultiHeroTotalCardsChart usernames={selectedUsers}/>
                        <MultiHeroTotalBurnedCardsChart usernames={selectedUsers}/>
                    </>
                )
            }
        </div>
    );
}

export default Charts;
