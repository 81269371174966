import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Avatar, Container } from '@mui/material';
import { TableSortLabel } from '@mui/material';
import api from './api';
import SearchBar from './SearchBar';

import { Link } from 'react-router-dom';

const TopChangeHerosDataTable = () => {
  const [data, setData] = useState([]);
  const [dataSorted, setDataSorted] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orderBy, setOrderBy] = useState('scoreChangePercentage');
  const [order, setOrder] = useState('asc');
  const [searchQuery, setSearchQuery] = useState('');
  useEffect(() => {
    const fetchData = async () => {
      try {
        api.get('https://api.shark43.com/top-burned-cards-change-percentages')
          .then(response => {
            setData(response.data);
            setLoading(false);
          })
          .catch(error => {
            setError(error);
            setLoading(false);
          });
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSort = columnId => {
    const isAsc = orderBy === columnId && order === 'asc';
    setOrderBy(columnId);
    setOrder(isAsc ? 'desc' : 'asc');
    // Sort data array here
    setData([...data].sort((a, b) => {
      if (isAsc) {
        return a[columnId] < b[columnId] ? -1 : 1;
      } else {
        return a[columnId] > b[columnId] ? -1 : 1;
      }
    }));
  };

  useEffect(() => {
    if(searchQuery === '' || !searchQuery || searchQuery === null || searchQuery === undefined) {
      setDataSorted(data)
    } else {
      const filteredHeroes = data.filter(hero => {
        const name = hero.name.toLowerCase();
        const handle = hero.handle.toLowerCase();
        const searchTextLower = searchQuery.toLowerCase();
        
        return name.includes(searchTextLower) || handle.includes(searchTextLower);
      });
      setDataSorted(filteredHeroes)
    }
  }, [searchQuery, data]) 

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleSearch = query => {
    setSearchQuery(query);
  };

  return (
    <Container sx={{pt: 2, pb: 2}}>
    <SearchBar  onSearch={handleSearch} />
    <TableContainer component={Paper}>
      <Table>
        <TableHead id="TableHeadM">
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'name'}
                direction={orderBy === 'name' ? order : 'asc'}
                onClick={() => handleSort('name')}
              >
                Username
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                  active={orderBy === 'rank'}
                  direction={orderBy === 'rank' ? order : 'asc'}
                  onClick={() => handleSort('rank')}
                >
                  Rank
                </TableSortLabel>
            </TableCell>            
            <TableCell>
              <TableSortLabel
                  active={orderBy === 'star'}
                  direction={orderBy === 'star' ? order : 'asc'}
                  onClick={() => handleSort('star')}
                >
                  Star
                </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                  active={orderBy === 'start'}
                  direction={orderBy === 'start' ? order : 'asc'}
                  onClick={() => handleSort('start')}
                >
                  BURNED START
                </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                  active={orderBy === 'end'}
                  direction={orderBy === 'end' ? order : 'asc'}
                  onClick={() => handleSort('end')}
                >
                  BURNED UNTIL NOW
                </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                  active={orderBy === 'percentageIncrease'}
                  direction={orderBy === 'percentageIncrease' ? order : 'asc'}
                  onClick={() => handleSort('percentageIncrease')}
                >
                  CHANGE %
                </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataSorted.map((item, index) => (
            <TableRow key={index} component={Link} to={`/hero/${item.handle}`}
              className={
                item.percentageIncrease < -10 ? 'red'
                  : item.percentageIncrease >= -1 && item.percentageIncrease < 0 ? 'v-red'
                  : item.percentageIncrease >= -10 && item.percentageIncrease < 0 ? 'light-red'
                  : item.percentageIncrease >= 0 && item.percentageIncrease < 1 ? 'light-green'
                  : item.percentageIncrease >= 1 && item.percentageIncrease < 10 ? 'light-green'
                  : 'green'
              }
            >
              <TableCell>
                <Avatar alt={item.name} 
                    src={`https://www.fantasy.top/_next/image?url=https%3A%2F%2Ffantasy-top-cards.s3.eu-north-1.amazonaws.com%2Fv1%2Fcommon%2F${item?.handle}_${item?.star}.png&w=640&q=75`}
                />
              </TableCell>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.rank}</TableCell>
              <TableCell>{item.star}</TableCell>
              <TableCell>{parseFloat(item.start).toFixed(0)}</TableCell>
              <TableCell>{parseFloat(item.end).toFixed(0)}</TableCell>
              <TableCell>{parseFloat(item.percentageIncrease).toFixed(2)}</TableCell>
              {/* Add more TableCell components for additional data fields */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Container>
  );
};

export default TopChangeHerosDataTable;
