import React from 'react';
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';

function FormatToggle({ options, selectedFormat, onFormatChange }) {
  return (
    <Tooltip style={{width: '100%'}} title="Switch between time formats">
      <ToggleButtonGroup
        value={selectedFormat}
        exclusive
        onChange={onFormatChange}
        aria-label="time format"
      >
        {options.map(option => (
          <ToggleButton style={{width: '100%'}} key={option.value} value={option.value} aria-label={option.label}>
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Tooltip>
  );
}

export default FormatToggle;
