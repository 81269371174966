import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, TextField, Button, Container, Typography, Paper, Grid, Card, CardMedia, CardContent, Rating } from '@mui/material';
import api from './api';
import './HeroProfileCardStyles.css';
import ShowBestDeck5 from './ShowBestDeck5';
import SearchAutocomplete from './SearchAutocomplete';

const HeroCard = ({ hero, onStarChange }) => {
  return (
    <Card>
      <CardMedia
        component="img"
        height="140"
        image={hero.picture_url}
        alt={hero.handle}
      />
      <CardContent>
        <Typography variant="h6" component="div">
          {hero.handle} - {hero.name}
        </Typography>
        <Rating
          name={`rating-${hero.hero_id}`}
          value={hero.star}
          onChange={(event, newValue) => {
            onStarChange(hero.hero_id, newValue);
          }}
          max={7}
        />
        <TextField
          label="Stars"
          type="number"
          value={hero.star}
          onChange={(event) => {
            const newValue = Number(event.target.value);
            onStarChange(hero.hero_id, newValue);
          }}
          inputProps={{ min: 0, max: 7 }}
        />
      </CardContent>
    </Card>
  );
};


const BestTeamWrapper2 = () => {
    const [bestTeams, setBestTeamsx] = useState([]);
    const [loading, setLoading] = useState(true);
    const [max_star, setMaxStar] = useState(20);
    const [selectedHeroesCard, setSelectedHeroesCard] = useState([]);
    const [heroForTeam, setHeroForTeam] = useState([]);
    const [excludedHandles, setExcludedHandles] = useState([]);

    useEffect(() => {
        fetchUserData();
    }, []);

    const fetchUserData = async (params = {}) => {
        setLoading(true);
        try {
            const response = await api.post('https://api.shark43.com/find_best_team2', { ...params });
            console.log('best deck', response.data);
            setBestTeamsx(response.data);
        } catch (error) {
            console.error('Error while fetching hero profile data', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
      const filters = {
          stars: max_star,
          excluded_handles: excludedHandles,
          heroes_for_team: heroForTeam,
      };
      fetchUserData(filters);
    }, [max_star, excludedHandles, selectedHeroesCard, heroForTeam]);    
    
    const fetchHeroForTeam = async (params) => {
      console.log('dio merda bastardo', params)
      try {
          const response = await api.post('https://api.shark43.com/heros-for-bestteam', { ...params });
          console.log('dio merda bastardo', response.data, params);
          const handleOrder = selectedHeroesCard.reduce((acc, handle, index) => {
            acc[handle] = index;
            return acc;
          }, {});
          response.data.sort((a, b) => handleOrder[a.handle] - handleOrder[b.handle]);
          
          setHeroForTeam(response.data);
      } catch (error) {
          console.error('Error while fetching hero profile data', error);
      } 
  };

    // useEffect(() => {
    //   fetchHeroForTeam({
    //     group_handles: selectedHeroesCard,
    //   })
    // }, [selectedHeroesCard]);

    const handleSelectHeroesCard = (handles) => {
      setSelectedHeroesCard(handles);
      fetchHeroForTeam({
        group_handles: handles,
      })
    };

    const handleExcludeUsers = (handles) => {
      setExcludedHandles(handles);
    };
    const handleStarChange = (heroId, newValue) => {
      setHeroForTeam(heroForTeam.map(hero => 
        hero.hero_id === heroId ? { ...hero, star: newValue } : hero
      ));
    };

    return (
      <Container>
        <Box>
          <Container>
            <Paper elevation={3} style={{ padding: '20px', marginBottom: '5px', marginTop: '20px' }}>
                <Box display="flex" justifyContent="space-around" alignItems="center" gap={2}>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Typography variant="subtitle1" align="center" gutterBottom>
                            STARS
                        </Typography>
                        <TextField
                            variant="outlined"
                            type="number"
                            value={max_star}
                            onChange={(e) => setMaxStar(e.target.value)}
                            inputProps={{ min: 5, max: 35 }}
                            required
                            fullWidth
                        />
                    </Box>
                    <Button type="submit" variant="contained" color="primary" style={{ height: 'fit-content', marginTop: '24px' }}>
                        APPLY AND GET BEST TEAMS
                    </Button>
                </Box>
                <Box style={{marginTop: '5px'}}>
                  <Typography variant='h5'>MY HEROES CARD</Typography>
                  <SearchAutocomplete onSelectUsers={handleSelectHeroesCard} />
                </Box>
                <Box style={{marginTop: '5px'}}>
                  <Typography variant='h5'>SELECT HEROES TO EXCLUDE FROM THE TEAM</Typography>
                  <SearchAutocomplete onSelectUsers={handleExcludeUsers} />
                </Box>
            </Paper>
            <Container>
              <Grid container spacing={2}>
                {heroForTeam.map(hero => (
                  <Grid item xs={12} sm={6} md={4} key={hero.hero_id}>
                    <HeroCard hero={hero} onStarChange={handleStarChange} />
                  </Grid>
                ))}
              </Grid>
          </Container>
          </Container>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                    <CircularProgress />
                </Box>
            ) : (

                bestTeams.length === 0 ? (
                  <>
                    <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                      <Typography variant="h1" align="center" gutterBottom>
                          There are no decks that meet these parameters
                        </Typography>
                    </Box>
                  </>
                ) : (
                  <Container sx={{pt: 2, pb: 2}}>
                    {bestTeams.map((team, i) => (<ShowBestDeck5 max_star={max_star} key={i} number={i + 1} team={team}/>))}
                  </Container>
                )
              
                
            )
            }
        </Box>
      </Container>
    );
};

export default BestTeamWrapper2;
