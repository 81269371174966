import React, { useState, useEffect } from 'react';
import api from './api';
import { Chart } from 'react-google-charts';

const FantasyScoreChart = ({ username }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
        try {
          api.get(`https://api.shark43.com/fantasy_score_over_time/${username}`).then(response => {
            setData(response.data);
            setLoading(false);
          })
          .catch(error => {
            setError(error);
            setLoading(false);
          });
        } catch (error) {
          setError(error);
          setLoading(false);
        }
      };

      fetchData();
    }, [username]);

    if (loading) {
      return <div>Loading...</div>;
    }

    if (error) {
      return <div>Error: {error.message}</div>;
    }

    const chartData = [
      ['Time', 'Fantasy Score'],
      ...data.map(item => [new Date(item.timestamp), item.fantasy_score])
    ];

    // Find the minimum and maximum floor values
    const minScore = Math.min(...data.map(item => item.fantasy_score));
    const maxScore = Math.max(...data.map(item => item.fantasy_score));

    // Add padding to the vertical axis range
    const padding = 1; // Adjust the padding as needed
    const minY = minScore - (minScore - minScore) * padding;
    const maxY = maxScore + (maxScore - maxScore) * padding;

    console.log(minY, maxY);

    return (
      <div>
          <h2>Fantasy Score Chart for {username}</h2>
          <Chart
            width={'100%'}
            height={'400px'}
            chartType="LineChart"
            loader={<div>Loading Chart...</div>}
            data={chartData}
            options={{
              title: 'Fantasy Score Over Time',
              hAxis: {
                title: 'Time',
                format: 'MMM d, yyyy HH:mm',
              },
              vAxis: {
                title: 'Fantasy Score',
                maxValue: maxY + (maxY * 0.008) ,
                minValue: minY - (minY * 0.008),
                
              },
              legend: 'none',
              pointSize: 4,
          backgroundColor: '#f0f0f0', // Light gray background for the chart area
          fontName: 'Arial', // Set font for chart labels
          fontSize: 12, // Set font size for chart labels
          colors: ['#2196f3'], // Set chart line color (blue)
          hAxis: {
            gridlines: { color: '#eee' }, // Set gridline color for x-axis
            textStyle: { color: '#666' }, // Set text color for x-axis labels
          },
          vAxis: {
            gridlines: { color: '#eee' }, // Set gridline color for y-axis
            textStyle: { color: '#666' }, // Set text color for y-axis labels
            }}}
          />
      </div>
    );
};

export default FantasyScoreChart;
