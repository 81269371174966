// Home.js

import React, { useState } from 'react';

import FantasyScoreChart from './FantasyScoreChart';
import HeroFloorChart from './HeroFloorChart';
import HeroViewsChart from './HeroViewsChart';
import HeroFollowersChart from './HeroFollowersChart';
import HeroProfileCard from './HeroProfileCard';
import StockChartFantasyScoreChart from './StockChartFantasyScoreChart';
import StockChartFloorChart from './StockChartFloorChart';
import { Routes, Route, useParams } from 'react-router-dom';
import './HeroStyles.css';

const Hero = () => {
    const { username } = useParams();

    return (
        <div className="hero-container">
        <HeroProfileCard username={username} />
        <div className="chart-grid">
          <StockChartFantasyScoreChart username={username}/>
          <StockChartFloorChart username={username}/>
          <FantasyScoreChart username={username} />
          <HeroFloorChart username={username} />
          <HeroViewsChart username={username} />
          <HeroFollowersChart username={username} />
        </div>
      </div>
    );
}

export default Hero;
