import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import api from './api';


function SearchAutocomplete({ onSelectUsers }) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [originalOptions, setOriginalOptions] = useState([]); // Store the original options

  const fetchOptions = async (query) => {
    setLoading(true);
    try {
      const response = await api.get(`https://api.shark43.com/heros-full-listname`);
      setOriginalOptions(response.data); // Store the original options
      setOptions(response.data);
    } catch (error) {
      console.error('Error fetching options:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event, value) => {
    if (!value) {
      setOptions(originalOptions); // Reset options to the original list when input is cleared
      return;
    }
    const lowercasedValue = value.toLowerCase();
    const filteredOptions = options.filter(option =>
      option.handle.toLowerCase().includes(lowercasedValue) || 
      option.name.toLowerCase().includes(lowercasedValue)
    );
    setOptions(filteredOptions);
  };

  const handleSelectUsers = (event, value) => {
    console.log(value)
    setSelectedUsers(value);
    const selectedHandles = value.map(user => user.handle);
    onSelectUsers(selectedHandles); // Pass selected handles to parent component
    setOptions(originalOptions); // Reset options to the original list after selection
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  return (
    <Autocomplete
      multiple
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={options}
      loading={loading}
      onInputChange={handleInputChange}
      onChange={handleSelectUsers}
      getOptionLabel={(option) => option.name} // Use the name field as the label
      renderOption={(props, option) => (
        <li {...props} key={"Dio"+option.handle+"_"+option.name} >
          {option.handle} - {option.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search heroes"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default SearchAutocomplete;