// Home.js

import React from 'react';

import TopChangeHerosDataTable from './TopChangeHerosDataTable';
const TopChangesPage = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <TopChangeHerosDataTable />
      </div>
    );
}

export default TopChangesPage;
