import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const Navbar = () => {
    return (
        <AppBar position="static">
            <Toolbar>
                {/* <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                >
                    <MenuIcon />
                </IconButton> */}
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    HERO FANTASY DATA BY <a href='https://twitter.com/Shark43Fapu'>SHARK43</a>
                </Typography>
                <Button color="inherit" component={Link} to="/">Home</Button>
                <Button color="inherit" component={Link} to="/layer0-sybil-checker">Layer 0 Sybil Checker</Button>
                <Button color="inherit" component={Link} to="/best-deck">BEST DECK</Button>
                <Button color="inherit" component={Link} to="/charts">CHARTS</Button>
                <Button color="inherit" component={Link} to="/top-changes">TOP CHANGES</Button>
                <Button color="inherit" component={Link} to="/top-burned">TOP BURNED</Button>
                <Button color="inherit" onClick={() => window.open("https://twitter.com/Shark43Fapu", "_blank")}>Twitter</Button>
            </Toolbar>
        </AppBar>
    );
}

export default Navbar;