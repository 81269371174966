// App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './Home';
import Hero from './Hero';
import BestDeck from './BestDeck';
import BestDeck2 from './BestDeck2';
import BestDeck3 from './BestDeck3';
import Navbar from './Navbar';
import Charts from './Charts';
import TopChangesPage from './TopChangesPage';
import TopBurnedCardsPage from './TopBurnedCardsPage';
import SybilChecker from './SybilChecker';


function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/best-deck" element={<BestDeck />} />
          <Route path="/best-deck2" element={<BestDeck2 />} />
          <Route path="/best-deck3" element={<BestDeck3 />} />
          <Route path="/layer0-sybil-checker" element={<SybilChecker />} />
          <Route path="/charts" element={<Charts />} />
          <Route path="/top-changes" element={<TopChangesPage />} />
          <Route path="/hero/:username" element={<Hero />} />
          <Route path="/top-burned" element={<TopBurnedCardsPage />} />
          <Route path="/heroes" element={<Home />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
