import React, { useState } from 'react';
import { TextField, Button, Typography, Box, Container } from '@mui/material';
import api from './api';

const SybilChecker = () => {
  const [address, setAddress] = useState('');
  const [message, setMessage] = useState('');
  const [isSybil, setIsSybil] = useState(null);

  const handleCheckAddress = async () => {
    try {
      const response = await api.get('https://api.shark43.com/layer0-check-address', { params: { address } });
      const { sybil } = response.data;
      if (sybil === 1) {
        setIsSybil(true);
        setMessage('User is marked as Sybil and the wallet is banned.');
      } else {
        setIsSybil(false);
        setMessage('Address is clean.');
      }
    } catch (error) {
      setIsSybil(null);
      setMessage('Error checking the address.');
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleCheckAddress();
    }
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: '50px' }}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <TextField
          label="Ethereum Wallet Address"
          variant="outlined"
          fullWidth
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          onKeyPress={handleKeyPress}
          style={{ marginBottom: '20px' }}
        />
        <Button variant="contained" color="primary" onClick={handleCheckAddress}>
          Check Address
        </Button>
        {message && (
          <Typography
            variant="h6"
            style={{
              color: isSybil ? 'red' : 'green',
              fontWeight: 'bold',
              marginTop: '20px',
            }}
          >
            {message}
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default SybilChecker;