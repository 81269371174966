import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, TextField, Button, Container, Typography, Paper } from '@mui/material';
import api from './api';
import './HeroProfileCardStyles.css';
import ShowBestDeck5 from './ShowBestDeck5';
import SearchAutocomplete from './SearchAutocomplete';

const BestTeamWrapper = () => {
    const [bestTeams, setBestTeamsx] = useState([]);
    const [loading, setLoading] = useState(true);
    const [max_star, setMaxStar] = useState(20);
    const [max_floor, setMaxFloorPrice] = useState(null);
    const [selectedHandles, setSelectedHandles] = useState([]);
    const [selectedHeroesCard, setSelectedHeroesCard] = useState([]);
    const [excludedHandles, setExcludedHandles] = useState([]);

    useEffect(() => {
        fetchUserData();
    }, []);

    const fetchUserData = async (params = {}) => {
        setLoading(true);
        try {
            const response = await api.post('https://api.shark43.com/find_best_team', { ...params });
            console.log('best deck', response.data);
            setBestTeamsx(response.data);
        } catch (error) {
            console.error('Error while fetching hero profile data', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
      console.log('handles dio',  selectedHandles)
      const filters = {
          stars: max_star,
          floorPrice: max_floor !== null ? max_floor : undefined,
          required_handles: selectedHandles, 
          excluded_handles: excludedHandles,
          group_handles: selectedHeroesCard,
      };
      fetchUserData(filters);
    }, [max_star, max_floor, selectedHandles, excludedHandles, selectedHeroesCard]);

    const handleSelectUsers = (handles) => {
      setSelectedHandles(handles);
    };    
    const handleSelectHeroesCard = (handles) => {
      setSelectedHeroesCard(handles);
    };

    const handleExcludeUsers = (handles) => {
      setExcludedHandles(handles);
    };


    const handleFilterSubmit = (e) => {
        e.preventDefault();
        const filters = {
            stars: max_star,
            floorPrice: max_floor !== null ? max_floor : undefined,
            required_handles: selectedHandles,
            excluded_handles: excludedHandles,
        };
        fetchUserData(filters);
    };

    return (
      <Container>
        <Box>
          <Container>
            <Paper elevation={3} style={{ padding: '20px', marginBottom: '5px', marginTop: '20px' }}>
                <Box display="flex" justifyContent="space-around" alignItems="center" gap={2}>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Typography variant="subtitle1" align="center" gutterBottom>
                            STARS
                        </Typography>
                        <TextField
                            variant="outlined"
                            type="number"
                            value={max_star}
                            onChange={(e) => setMaxStar(e.target.value)}
                            inputProps={{ min: 5, max: 35 }}
                            required
                            fullWidth
                        />
                    </Box>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Typography variant="subtitle1" align="center" gutterBottom>
                            MAX FLOOR PRICE
                        </Typography>
                        <TextField
                            variant="outlined"
                            type="number"
                            value={max_floor || ''}
                            onChange={(e) => setMaxFloorPrice(e.target.value === '' ? null : e.target.value)}
                            fullWidth
                        />
                    </Box>
                    <Button type="submit" variant="contained" color="primary" style={{ height: 'fit-content', marginTop: '24px' }}>
                        APPLY AND GET BEST TEAMS
                    </Button>
                </Box>
                <Box style={{marginTop: '5px'}}>
                  <Typography variant='h5'>MY HEROES CARD</Typography>
                  <SearchAutocomplete onSelectUsers={handleSelectHeroesCard} />
                </Box>
                <Box style={{marginTop: '5px'}}>
                  <Typography variant='h5'>SELECT REQUIRED HEROES IN THE TEAM</Typography>
                  <SearchAutocomplete onSelectUsers={handleSelectUsers} />
                </Box>
                <Box style={{marginTop: '5px'}}>
                  <Typography variant='h5'>SELECT HEROES TO EXCLUDE FROM THE TEAM</Typography>
                  <SearchAutocomplete onSelectUsers={handleExcludeUsers} />
                </Box>
            </Paper>
          </Container>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                    <CircularProgress />
                </Box>
            ) : (

                bestTeams.length === 0 ? (
                  <>
                    <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                      <Typography variant="h1" align="center" gutterBottom>
                          There are no decks that meet these parameters
                        </Typography>
                    </Box>
                  </>
                ) : (
                  <Container sx={{pt: 2, pb: 2}}>
                    {bestTeams.map((team, i) => (<ShowBestDeck5 max_star={max_star} key={i} number={i + 1} team={team}/>))}
                  </Container>
                )
              
                
            )
            }
        </Box>
      </Container>
    );
};

export default BestTeamWrapper;
