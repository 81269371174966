import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid, Box, Avatar, CardHeader } from '@mui/material';
import api from './api';
import './HeroProfileCardStyles.css';
import { Link } from 'react-router-dom';

const ShowBestDeck5 = ({ team, number = 1, max_star= 20 }) => {
    return (
      <Card>
        <CardHeader title={`BEST DECK #${number} WITH LESS THAN ${max_star} STAR: Total score: ${parseInt(team?.maxScore)} | Total price: ${parseFloat(team?.total_floor).toFixed(3)} ETH`} />
        <CardContent>
          <Grid container spacing={0} justifyContent={'center'}>
            {team?.heroes.map((hero, index) => (
              <Grid item xs={2} key={index}>
                <Link to={`/hero/${hero?.handle}`}>
                  <Box
                    sx={{
                      position: 'relative',
                      width: '100%',
                      height: '100%', // Set height to 100%
                      maxHeight: '200px', // Adjust the maximum height of the box
                      maxWidth: '150px', // Adjust the maximum width of the box
                      marginBottom: '8px', // Add margin at the bottom to separate boxes
                    }}
                  >
                    <img
                      src={`https://www.fantasy.top/_next/image?url=https%3A%2F%2Ffantasy-top-cards.s3.eu-north-1.amazonaws.com%2Fv1%2Fcommon%2F${hero.handle}_${hero.star}.png&w=640&q=75`}
                      alt="Card Image"
                      style={{
                        width: '100%',
                        height: '100%', // Set height to 100%
                        objectFit: 'cover', // Maintain aspect ratio
                      }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        position: 'absolute',
                        bottom: '20px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        backgroundColor: 'rgba(100, 255, 149, 0.8)',
                        padding: '4px 8px',
                        borderRadius: '4px',
                        zIndex: 999999,
                        whiteSpace: 'nowrap', // Prevent line break

                      }}
                    >
                      {parseInt(hero.score)} - {parseFloat(hero.floor).toFixed(4)}E
                    </Typography>
                  </Box>
                </Link>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    )
  };
  
export default ShowBestDeck5;
  